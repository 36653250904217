@import '_variable';

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Manrope Light'), local('Manrope-Light'), url(../fonts/Manrope-Light.woff2) format('woff2'), url(../fonts/Manrope-Light.woff) format('woff');
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Manrope Regular'), local('Manrope-Regular'), url(../fonts/Manrope-Regular.woff2) format('woff2'), url(../fonts/Manrope-Regular.woff) format('woff');
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Manrope Bold'), local('Manrope-Bold'), url(../fonts/Manrope-Bold.woff2) format('woff2'), url(../fonts/Manrope-Bold.woff) format('woff');
    unicode-range: U+0-10FFFF;
}

body {
    font-family: 'Manrope';
    font-weight: 500;
    color: #2c3e50;
    background: #fff;
    .tox-tinymce {
        p, h2, h3, h4, h5, h6 {
            font-family: 'Manrope';
            font-weight: 500;

            strong {
                font-weight: 700;
            }
        }
    }
}

.linkbox_large, .linkbox_left, .linkbox_right, .Linkbox_rechts, .Linkbox_links, .Linkbox_gross {
    padding: 5px 15px 10px;
    border: 1px solid $dark;
    margin: 10px 0 10px 0;
    width: 100%;
    display: block;
    border-top: 30px solid $dark;
    color: $dark;

    &::before {
        content: 'Mehr zum Thema';
        margin-top: -31px;
        display: inherit;
        color: #fff;
        font-size: 15px;
        margin-bottom: 15px;
    }
}

.linkbox_left, .linkbox_right, .Linkbox_rechts, .Linkbox_links {
    width: 50%;
    padding: 5px 15px 10px 10px;
    margin: 10px 20px 20px 0;
}

.linkbox_left, .Linksbox_links {
    float: left;
}

.linkbox_right, .Linkbox_rechts {
    float: right;
}

img.inline_image {
    cursor: pointer;

    &.left {
        float: left;
        margin: 0 28px 16px 0;
    }

    &.right {
        float: right;
        margin: 0 0 16px 28px;
    }
}

p {
    font-size: 16px;
    line-height: 26px;
}

h2 {
    font-weight: 400;
    margin-top: 50px;
    font-size: 26px;
}

b, strong {
    font-weight: 700;
}

.social-media-icon {
    width: 28px;
    height: auto;
    margin: 10px 5px;
}
